/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Header from "../components/global/Header";
import { useNavigate } from "react-router-dom";
import Footer from "../components/global/Footer";
import axios from "axios";
import { REACT_APP_DEV_URL } from "../utils/api";
import toast from "react-hot-toast";
import { setSessionStorage } from "../utils/helpers";

const Admin = () => {
  const token = sessionStorage.getItem("token") || "";
  const [results, setResults] = useState<any>();
  const [resultText, setResultText] = useState("Loading...");
  const [selectedFiles, setSelectedFiles] = useState<any>();
  const [imageDisable, setImageDisable] = useState(false);
  const [fileDisable, setFileDisable] = useState(false);
  const [selectedCSV, setSelectedCSV] = useState<any>();
  const navigate = useNavigate();
  const imageInputRef = useRef<HTMLInputElement>(null);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const [csvError, setCsvError] = useState("");

  const fetchAwaitedMovies = async () => {
    try {
      const res = await axios.get(
        `${REACT_APP_DEV_URL}/selling-info/get-all-awaited-movies`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setResults(res.data.data);
      if (res?.data?.data?.length === 0) {
        setResultText("No results found");
      }
    } catch {
      toast.error("error during fetching the deals api");
    }
  };

  useEffect(() => {
    fetchAwaitedMovies();
  }, []);

  // for image onchange
  const handleFileChange = (event: any) => {
    const files = event.target.files; // Get selected files
    if (files) {
      const fileArray = Array.from(files);

      // Check if more than 100 files are selected
      if (fileArray.length > 100) {
        toast.error("You can only upload up to 100 files.");
        return;
      }

      setSelectedFiles(fileArray); // Store the files in the state
    }
  };

  // for image upload
  const handleUploadImage = async () => {
    setImageDisable(true);
    try {
      if (selectedFiles.length === 0) {
        toast.error("No file selected.");
        return;
      }
      const formData = new FormData();
      // Append each image to the FormData
      selectedFiles.forEach((file: any) => {
        formData.append("sellinginfo", file); // Use the key 'sellinginfo'
      });
      const res = await axios.post(
        `${REACT_APP_DEV_URL}/selling-info/bulk-upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.status !== 200) {
        toast.error(res.data.message);
      } else {
        toast.success(res.data.message);
        setSelectedFiles([]);
        if (imageInputRef.current) {
          imageInputRef.current.value = "";
        }
      }
    } catch {
      toast.error("error uploading file");
    } finally {
      setImageDisable(false);
    }
  };

  // for csv file onchange
  const handleCSVFileChange = (event: any) => {
    const file = event.target.files?.[0]; // Get the first selected file

    if (file && file.type === "text/csv") {
      setSelectedCSV(file); // Store the selected JSON file
    } else {
      toast.error("Please select a valid CSV file.");
    }
  };

  // for csv upload
  const handleUploadData = async () => {
    setFileDisable(true);
    if (!selectedCSV) {
      toast.error("No CSV file selected.");
      setFileDisable(false);
      return;
    }

    const formData = new FormData();
    formData.append("data", selectedCSV);
    console.log("data", selectedCSV);

    try {
      const res = await axios.post(
        `${REACT_APP_DEV_URL}/selling-info/bulk-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res", res);

      if (res.data.status !== 200) {
        toast.error(res.data.message);
        setCsvError(res.data.message);
      } else {
        toast.success(res.data.message);
        setSelectedCSV(null);
        setCsvError("");
        if (csvInputRef.current) {
          csvInputRef.current.value = "";
        }
      }
    } catch (error: any) {
      // Handle status code 400 or other errors
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message); // Display message for 400 status
        setCsvError(error.response.data.message);
      } else {
        // For any other type of errors
        toast.error("Error uploading file.");
      }
    } finally {
      setFileDisable(false);
    }
  };

  return (
    <div>
      <div>
        <Header />

        {/* Awaiting approval  */}
        <div className="container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "40px",
              marginTop: "80px",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "35px",
                justifyContent: "center",
              }}
            >
              <p className="marketPlaceTitle">Awaiting approval </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "16px",
              marginBottom: "80px",
            }}
          >
            {results && results?.length > 0 ? (
              <>
                {results.map((item: any, index: number) => (
                  <div
                    style={{
                      background: "#2F144B",
                      border: "1px solid #2F144B",
                      borderRadius: "12px",
                      padding: "12px",
                      cursor: "pointer",
                    }}
                    key={index}
                    onClick={() => {
                      setSessionStorage("verifyMovie", item);
                      navigate(`/approve`);
                    }}
                  >
                    <img
                      src={item?.promotions?.moviePoster}
                      alt=""
                      height={260}
                      width={180}
                    />

                    <div
                      style={{
                        paddingBlock: "15px",
                      }}
                    >
                      <p
                        className="ft14 text-white"
                        style={{
                          paddingTop: "10px",
                        }}
                      >
                        {item?.contentType?.content?.contentTitle}
                      </p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <p className="text-white ft24">{resultText}</p>
            )}
          </div>
        </div>

        <div className="container" style={{ marginTop: "60px" }}>
          <h2 className="ft24 text-white">Uploads</h2>

          {/* image upload */}
          <div className="gridContainerStyle">
            <div className="gridItemStyle">
              {" "}
              <p className="contentLabelName">Image Upload</p>
              <div
                style={{
                  backgroundColor: "#2F144B",
                  height: "46px",
                  marginTop: "12px",
                  borderRadius: "5px",
                  display: "flex",
                  padding: "0px 2px",
                  position: "relative",
                }}
              >
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  ref={imageInputRef}
                  className=""
                  style={{ color: "white", padding: "10px" }}
                  onChange={handleFileChange}
                />

                <button
                  className="uploadBtn"
                  onClick={handleUploadImage}
                  disabled={imageDisable}
                >
                  {imageDisable ? "..." : "Upload"}
                </button>
              </div>
            </div>

            {/* json upload */}
            <div className="gridItemStyle">
              {" "}
              <p className="contentLabelName">Data Upload</p>
              <div
                style={{
                  backgroundColor: "#2F144B",
                  height: "46px",
                  marginTop: "12px",
                  borderRadius: "5px",
                  display: "flex",
                  padding: "0px 2px",
                  position: "relative",
                }}
              >
                <input
                  type="file"
                  accept=".csv"
                  className=""
                  ref={csvInputRef}
                  style={{ color: "white", padding: "10px" }}
                  onChange={handleCSVFileChange}
                />

                <button
                  className="uploadBtn"
                  onClick={handleUploadData}
                  disabled={fileDisable}
                >
                  {fileDisable ? "..." : "Upload"}
                </button>
              </div>
              {csvError !== "" && <p className="error">{csvError}</p>}
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Admin;
