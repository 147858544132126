import '../styles/document.css';

export const Document = (props: any) => {
    const { title, data } = props;
    return (
        <div className='documentContainer'>
            <h3 className='title'>
                {title}
            </h3>
            <div className='contentContainer'>
                {/* {data?.map((paragraph: string) => {
                    return (
                        <div>
                            <p>{paragraph}</p>
                            <br></br>
                        </div>
                    )
                })} */}
                <p className="blogContent">
                    <div dangerouslySetInnerHTML={{ __html: data }} />
                </p>
            </div>
        </div>
    )
}