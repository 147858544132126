import ToggleList from "../components/global/toggleList";

import sellLanding from "../assets/sellLanding.png"
import bgPurple1 from "../assets/bgPurple1.png";
import bgPurple2 from "../assets/bgPurple2.png";
import sellMidSection1 from "../assets/sellMidSection1.png";
import sellMidSection2 from "../assets/sellMidSection2.png";

import location from "../assets/location.png";
import catalog from "../assets/catalog.png";
import customize from "../assets/customize.png";
import paste from "../assets/paste.png";
import connect from "../assets/connect.png";
import helpline from "../assets/helpline.png";
import onboard from "../assets/onboard.png";
import moreReasonsImage from "../assets/moreReasons.png";
import checkCircle from "../assets/checkCircle.png";
import startBuying from "../assets/startBuyingBG.png";
import whatsappImage from "../assets/whatsappImage.png";

import "../styles/staticSell.css";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import { useNavigate } from "react-router-dom";

export function StaticSell() {
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `linear-gradient(90deg, #2F005D 40.5%, rgba(0, 0, 0, 0.5) 100%), url(${sellLanding})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "30vh",
    width: "100vw",
  };

  const backGroundStyleSection1 = {
    backgroundImage: `url(${bgPurple1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const backGroundStyleSection2 = {
    backgroundImage: `url(${bgPurple2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const startBuyingBackGroundStyle = {
    backgroundImage: `url(${startBuying})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const midSections = [
    {
      tagLine: "Secure & Streamlined \n content Rights sales",
      content:
        "Selling content rights safely and easily involves several key steps. First, clearly define the rights you intend to sell, whether they are distribution rights, broadcast rights, or digital streaming rights. Next, set up contracts and conduct thorough due diligence on the financial stability of potential buyers. Additionally, set transparent terms and conditions, including pricing, usage limits, and payment schedules. All of this can become overwhelming and complicate the process.",
      backgroundImage: backGroundStyleSection1,
      image: sellMidSection1,
      imageLeft: true,
    },
    {
      tagLine: "",
      content:
        "Sell your content rights fast and hassle-free. Producerbazaar's platform takes care of the legal complexities, so you can focus on creating.",
      backgroundImage: backGroundStyleSection2,
      image: sellMidSection2,
      imageLeft: false,
    },
  ];

  const reasonsToChooseProducerBazaar = [
    {
      image: location,
      content: "Safe and Verified market place",
    },
    {
      image: catalog,
      content: "Attract worldwide buyers",
    },
    {
      image: customize,
      content: "Customize rights & duration with few clicks",
    },
    {
      image: paste,
      content: "We do all the heavy lifting behalf of you",
    },
    {
      image: connect,
      content: "Connect with interested parties securely",
    },
    {
      image: helpline,
      content: "Conduct financial transactions with confidence",
    },
    {
      image: onboard,
      content: "Onboard in 3 simple steps",
    },
  ];

  const moreReasons = [
    {
      content: "Easy to search with 20+ filters",
    },
    {
      content: "5000+ movie database",
    },
    {
      content: "Exclusive dashboards for buyers and sellers",
    },
    {
      content: "Track everything in one place",
    },
  ];

  const buyingContentRights = [
    { text: 'Signing Up and Becoming a ProducerBazaar Seller', subsection: 'Welcome to ProducerBazaar! To embark on your journey of turning your films/creatives into revenue, you\'ll first need to register as a seller on our platform. The registration process is free, but there is a one-time onboarding fee. This fee covers essential services like setting up your seller profile, providing access to our content management tools, and ensuring your films are showcased effectively to potential buyers. By becoming a ProducerBazaar seller, you gain access to a vast network of potential buyers and powerful tools to manage your content and close deals.' },
    { text: 'Uploading Your Content and Taking Control', subsection: "<p>Congratulations on successfully onboarding to ProducerBazaar! Now, it's time to leverage the power of your seller dashboard. This is your central hub for managing your film content and making it shine for potential buyers.</p>\
    <p style='margin-bottom: 10px !important;'>Within the dashboard, you'll enjoy unlimited access to upload and edit all the essential details about your films. This includes:<p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp;  Titles and synopses</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp;  Cast and crew information</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp;  Genre classifications</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp; Awards and accolades</p>\
    <p style='margin-bottom: 10px !important;'>· &nbsp; High-quality trailers, posters, and other promotional materials (optional)</p>\
    <p style='margin-bottom: 10px !important;'>With complete control over your content, you can easily update information, adjust pricing, and showcase your films in the best possible light. The user-friendly dashboard empowers you to make changes and manage your film offerings at your convenience.</p>\
    " },
    { text: 'Uploading Multiple Films: Expanding Your Content Library', subsection: 'ProducerBazaar welcomes you to showcase your entire film library! To upload additional films, you\'ll need to complete a simple onboarding process for each new title. This ensures each film receives the dedicated attention it deserves and allows us to tailor its presentation to potential buyers. There is a separate onboarding fee associated with each additional film upload.' },
    { text: 'Ensuring Content Authenticity (Verification Process)', subsection: "<p style='margin-bottom: 10px !important;'>At ProducerBazaar, we take content ownership seriously. To safeguard our platform and ensure trust among buyers, we implement a verification process after you've successfully uploaded your film details in the dashboard.</p>\
    <p style='margin-bottom: 10px !important;'> This verification process confirms that you are the rightful owner of the content you're uploading. We may request additional documentation or utilize industry-standard verification methods. Rest assured, your information remains confidential throughout this process. Once verification is complete, your film will be pushed live to the marketplace, ready to be discovered by potential buyers.</p>\
    <p style='margin-bottom: 10px !important;'> This security measure protects both you, the seller, and potential buyers, fostering a secure and trustworthy environment for film transactions.</p>"},
    { text: 'Sharing Your Film Details for Wider Reach', subsection: 'ProducerBazaar empowers you to go beyond our internal marketplace. Once your film is live, we provide the option to share specific details (excluding the film itself) with external buyers. This strategic sharing allows you to reach a broader audience of potential buyers who may not be actively browsing ProducerBazaar about available rights (theatrical, OTT, etc.) to attract buyers specifically interested in those options.' },
    { text: 'Maintaining Your Film\'s Presence in the Marketplace', subsection: "<p style='margin-bottom: 10px !important;'>ProducerBazaar offers a platform to showcase your films for a period of one year following your initial onboarding. This timeframe ensures your content receives ample exposure to potential buyers.</p>\
    <p style='margin-bottom: 10px !important;'> To maintain your film listings beyond this initial year, a nominal retainer fee will apply. This fee helps us cover the costs associated with maintaining our platform, ensuring your films remain discoverable by interested buyers. The retainer fee structure is transparent and clearly communicated through email.</p>"},
    { text: 'Tracking Buyer Interest and Making the Deal', subsection: "<p style='margin-bottom: 20px !important;'>ProducerBazaar keeps you informed about potential buyers expressing interest in your films. </p>\
    <p style='margin-bottom: 10px !important;'><strong>a. Email Notifications:</strong></p>\
    <p style='margin-bottom: 20px !important;'>Whenever a buyer shows interest in your film, you'll receive a prompt email notification. This email will include masked details of the buyer to protect their confidentiality while still alerting you to their inquiry.</p>\
    <p style='margin-bottom: 10px !important;'><strong>b. Seller Dashboard Visibility:</strong></p>\
    <p style='margin-bottom: 10px !important;'>In addition to email alerts, your seller dashboard provides a clear view of all buyer interest for your films. This section will showcase any buyer inquiries, including the timeframe within which you must respond.</p>\
    <p style='margin-bottom: 20px !important;'>Through the dashboard, you can conveniently review Buyer Interest and proceed to Accept or Reject Offers.</p>\
    <p style='margin-bottom: 10px !important;'>Alternately you can also reach out to our CRM Team to initiate negotiations and get your queries clarified (if any)</p>" },
    { text: 'Completing the Sale and Delivering Your Content', subsection: "<p style='margin-bottom: 20px !important;'>Congratulations on securing a buyer for your film! Once you accept a buyer's offer, the following steps ensure a smooth transaction: </p>\
    <p style='margin-bottom: 10px !important;'>1. Content Ownership Verification: You may be required to provide additional proof of ownership for the film content. This could involve contracts, censor certificate, copyright registrations, or other relevant documentation. ProducerBazaar will guide you through this process to ensure everything is handled efficiently.</p>\
    <p style='margin-bottom: 20px !important;'>2. Delivering Your Content in the Buyer's Preferred Format: The buyer may specify the format they require the film content in for their specific needs. ProducerBazaar provides tools and resources to help you deliver your film in the requested format. Our team can also assist you with any technical aspects of content delivery.</p>\
    <p style='margin-bottom: 10px !important;'>By fulfilling these requirements, you'll finalize the sale and receive payment through our secure payment processing system. ProducerBazaar prioritizes clear communication and streamlined processes to ensure a successful outcome for both sellers and buyers.</p>" },
    { text: 'Transparency and Security of Sale with Blockchain Technology', subsection: 'Every sale you make on ProducerBazaar is recorded on a secure, tamper-proof blockchain ledger. This distributed ledger system ensures that no one can alter the details of your transactions, providing an ironclad record of your film rights. Both you and the buyer possess a permanent record of the transaction on the blockchain ledger. This record includes details like the film sold, the rights conveyed, and the due date for any payments. As part of the transaction, you\'ll be provided with a unique blockchain address and you can rest assured that your rights are protected, and your sales are recorded immutably for future reference.' },
  ];

  const Landing = () => {
    const tagLine = "Sell content rights \n Safely and Easily";
    return (
      <div className="staticSellCard">
        <div style={backgroundStyle}>
          <div className="tagLine">
            {tagLine.split("\n").map((line, index) => (
              <div key={index}>
                {line}
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const MidSection = () => {
    return (
      <div>
        {midSections.map((section) => {
          return (
            <div style={section.backgroundImage}>
              <div>
                {section.imageLeft && (
                  <div className="midSectionContainer">
                    <div>
                      <img className="midSectionImage" src={section.image} />
                    </div>
                    <div className="midSectionContentContainer">
                      <div className="midSectionTagLine">
                        {section.tagLine.split("\n").map((line, index) => (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        ))}
                      </div>
                      <div className="midSectionContent">{section.content}</div>
                    </div>
                  </div>
                )}
                {!section.imageLeft && (
                  <div className="midSectionContainer">
                    <div className="midSectionContentContainer">
                      <div className="midSectionTagLine">
                        {section?.tagLine}
                      </div>
                      <div className="midSectionContent midSectionContentText">{section.content}</div>
                    </div>
                    <div>
                      <img className="midSectionImage" src={section.image} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const ChooseProducerBazaar = () => {
    return (
      <div className="reasonsContainer">
        <div className="heading">Why Choose Producer Bazaar</div>
        <div className="reasonsToChooseContainer">
          {reasonsToChooseProducerBazaar.map((reason) => {
            return (
              <div className="reasonContainer">
                <img className="reasonImage" src={reason.image} />
                <div>{reason.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const MoreReasons = () => {
    return (
      <div className="moreReasonsContainer">
        <div className="heading">More Reasons To Choose Producer Bazaar</div>
        <div className="moreReasonsSection">
          <div className="moreReasonsImageContainer">
            <img className="moreReasonsImage" src={moreReasonsImage} />
          </div>
          <div className="moreReasonsContentContainer">
            <div className="moreReasonsContent">
              {moreReasons.map((reason) => {
                return (
                  <div className="moreReasons">
                    <div>
                      <img
                        className="moreResonsCheckCircle"
                        src={checkCircle}
                      />
                    </div>
                    <div className="reasonText">{reason.content}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const BuyingContentRights = () => {
    return (
      <div style={backGroundStyleSection1}>
        <div className="buyingContentRightsContainer">
          <div className="heading">
            Buying Content Rights With Producer Bazaar
          </div>
          <div>
            <ToggleList sentences={buyingContentRights} />
          </div>
        </div>
      </div>
    )
  }

  const StartBuying = () => {
    return (
      <div style={startBuyingBackGroundStyle}>
        <div>
          <div className="heading">
            <p style={{ marginBottom: '-30px' }}>Start Buying Movie & Digital Rights</p>
            <p>At the right price </p>
          </div>
          <div className="startBuyingContainer">
            <a href="https://wa.me/917200711366" target="_blank"><div className="whatsappChatContainer">
              <img className="whatsappChatImage" src={whatsappImage} />
              <div className="textStyle">Chat with sales team</div>
            </div></a>
            <div onClick={() => navigate("/sign-up")} className="signUpForFreeContainer">
              Signup
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="testAuth">
        <Header></Header>
        <Landing></Landing>
        <MidSection></MidSection>
        <ChooseProducerBazaar></ChooseProducerBazaar>
        <BuyingContentRights />
        <StartBuying />
        <Footer></Footer>
      </div>
    </div>
  );
}
