/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "../styles/admin.css";
import Sell from "./Sell";
import { getSessionStorage, setSessionStorage } from "../utils/helpers";

const Approve = () => {
  const getMovie = getSessionStorage("verifyMovie");
  const [loading, setLoading] = useState(true); // Loading state

  // const fetchContentType = (param: string) => {
  //   switch (param) {
  //     case "Documentary":
  //     case "Short Film":
  //       return {
  //         contentTitle: getMovie?.contentType?.content?.contentTitle,
  //         originalLanguage: getMovie?.contentType?.content?.originalLanguage,
  //         audio: getMovie?.contentType?.content?.audio?.split(","),
  //         subtitle: getMovie?.contentType?.content?.subtitle?.split(","),
  //       };
  //     case "Television Series":
  //     case "Web Series":
  //       return {
  //         contentTitle: getMovie?.contentType?.content?.contentTitle,
  //         originalLanguage: getMovie?.contentType?.content?.originalLanguage,
  //         audio: getMovie?.contentType?.content?.audio?.split(","),
  //         subtitle: getMovie?.contentType?.content?.subtitle?.split(","),
  //         season: getMovie?.contentType?.content?.season,
  //         noOfEpisodes: getMovie?.contentType?.content?.noOfEpisodes,
  //       };
  //     case "Music Albums":
  //       return {
  //         contentTitle: getMovie?.contentType?.content?.contentTitle,
  //         audio: getMovie?.contentType?.content?.audio?.split(","),
  //         duration: getMovie?.contentType?.content?.duration,
  //       };
  //     case "Events & Live Shows":
  //       return {
  //         category: getMovie?.contentType?.content?.category,
  //         originalLanguage: getMovie?.contentType?.content?.originalLanguage,
  //         trademark: getMovie?.contentType?.content?.trademark,
  //         audio: getMovie?.contentType?.content?.audio?.split(","),
  //         subtitle: getMovie?.contentType?.content?.subtitle?.split(","),
  //         markets: getMovie?.contentType?.content?.markets?.split(","),
  //       };
  //     default:
  //       return null; // Optional: Add a default case if the param doesn't match any case
  //   }
  // };

  useEffect(() => {
    // console.log("getMovie", getMovie);

    const synopsisValues = {
      synopsis: getMovie.synopsisGenre.synopsis,
      selectedGenres: getMovie.synopsisGenre.genre,
      copyRightValues: getMovie.synopsisGenre.registeredUnderIprAct,
      isChecked:
        getMovie.synopsisGenre.registeredUnderIprAct.length > 0 ? true : false,
    };

    const advisoryValues = {
      censorCeritificateUrl: getMovie.advisory.indianCensorCertificate,
      movieDurationTime: getMovie.advisory.movieDurationInMin,
      internationalMovieRating: getMovie.advisory.internationalMovieFilmRating,
      indianMovieRating: getMovie.advisory.indianMovieFilmRating,
      region: getMovie.advisory.region,
      state: getMovie.advisory.state,
      councilName: getMovie.advisory.councilName,
    };

    const promotionValues = {
      moviePosterUrl: getMovie.promotions.moviePoster,
      movieTrailerUrl: getMovie.promotions.trailerLink,
      imdbUrl: getMovie.promotions.imdbUrl,
      wikipediaUrl: getMovie.promotions.wikiLink,
      edirUrl: getMovie.promotions.edir,
      unreleasedUrl: getMovie.promotions.unreleasedLink,
      awardsUrl: getMovie.promotions.awards.map((item: any) => item.url),
    };

    const produceValues = {
      productionHouseName: getMovie.producerInfo.houseName,
      producerFirstName: getMovie.producerInfo.fname,
      producerLastName: getMovie.producerInfo.lname,
      email: getMovie.producerInfo.email,
      address: getMovie.producerInfo.address,
      phone: getMovie.producerInfo.phone.slice(2),
    };

    const rightValues = {
      TheatricalRights: getMovie.rights.theatricalRights,
      OtherRights: getMovie.rights.otherRights,
      EmergingRights: getMovie.rights.emergingRights,
      OverseasRights: getMovie.rights.overseaseRights,
      "Dubbing&RemakeRights": getMovie.rights.dubbingRights,
      Audio: getMovie.rights.audio,
      TravelRights: getMovie.rights.travel,
      DigitalRights: getMovie.rights.digitalRights,
      TelevisionRights: getMovie.rights.televisionRights,
    };

    setSessionStorage("Synopsis & Genre", synopsisValues);
    setSessionStorage("Producer Information", produceValues);
    setSessionStorage("Rights Available", rightValues);
    setSessionStorage("Distribution & Release", getMovie.distributionRelease);
    setSessionStorage("Advisory", advisoryValues);
    setSessionStorage("Promotions", promotionValues);
    setSessionStorage("Cast & Crew", getMovie.castCrew);
    sessionStorage.setItem("contentType", getMovie.contentType.type);
    sessionStorage.setItem(
      "movie status",
      getMovie.distributionRelease.movieStatus
    );
    setSessionStorage(getMovie.contentType.type, getMovie.contentType.content);
    // setSessionStorage(
    //   getMovie.contentType.type,
    //   fetchContentType(getMovie.contentType.type)
    // );

    setLoading(false);
  }, []);

  return (
    <div>
      {loading ? (
        <p style={{ fontSize: "48px", color: "white" }}>
          Hang tight, the show is about to begin...
        </p> // Show this while loading
      ) : (
        <Sell /> // Show Sell component once loading is complete
      )}
    </div>
  );
};

export default Approve;
