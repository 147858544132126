import { Document } from "../components/Document";
import Footer from "../components/global/Footer";
import Header from "../components/global/Header";

export const CookiePolicy = () => {
    const title = 'Cookies Policy for Producerbazaar Marketplace';
    const documentData = "<p style='margin-bottom: 10px !important;'><strong>Effective Date: 1 st October 2024</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Introduction</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>Producerbazaar Marketplace (&quot;we,&quot; &quot;our,&quot; or &quot;us&quot;) uses cookies and similar tracking technologies on our website <span style='text-decoration: underline;'>https://www.producerbazaar.com</span>. This Cookies Policy explains what cookies are, how we use them, and your choices regarding their use.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>By continuing to browse or use our site, you agree to our use of cookies in accordance with this Cookies Policy. If you do not agree to our use of cookies, you can disable them by following the instructions provided below.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>What Are Cookies?</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>Cookies are small text files stored on your device (computer, tablet, or mobile) when you visit a website. They help websites recognize your device and store information about your preferences or actions over time.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>Cookies can be &quot;session&quot; cookies, which are deleted when you close your browser, or &quot;persistent&quot; cookies, which remain on your device until their expiration date or until you delete them.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>How We Use Cookies?</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>We use cookies to enhance your experience on Producerbazaar Marketplace. Cookies allow us to:</p>\n\n\
    <p style='margin-bottom: 10px !important;'>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Authenticate Users: </strong>Ensure that you are able to securely log in to your account. </p>\n\n\
    <p style='margin-bottom: 10px !important;'>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Remember Your Preferences: </strong>Save your preferences such as language selection and login information.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Analyze Usage Patterns: </strong>Collect data about your visit, such as the pages you view, the links you click, and how long you stay on our site. This helps us improve the website’s functionality and performance.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <strong>Enable Essential Features: </strong>Some cookies are essential for the operation of our website and allow you to use key features such as shopping carts and secure areas.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Types of Cookies We Use</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>1. Strictly Necessary Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>These cookies are essential for the operation of our website and for you to use some of its features, such as accessing secure areas. Without these cookies, certain services cannot be provided.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>2. Performance and Analytics Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>These cookies help us understand how visitors interact with our website by collecting anonymous information, such as the number of visitors, the time they spend on the site, and the pages they visit. This data is used to improve our services and enhance your browsing experience.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>3. Functionality Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>These cookies allow us to remember the choices you make on the website, such as your language preferences or login information, to provide you with a more personalized experience.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>4. Targeting and Advertising Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>We may use these cookies to deliver advertisements that are relevant to your interests, based on your browsing behavior on our website. These cookies also help us limit the number of times you see an ad and measure the effectiveness of ad campaigns.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Third-Party Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>We may allow third-party service providers, such as analytics providers (e.g., Google Analytics) and advertisers, to place cookies on your device when you visit our site. These third parties may use the information collected to track your online activities across different websites.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>You can learn more about how Google Analytics uses cookies by visiting their privacy policy: Google Analytics PrivacyPolicy.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Your Choices Regarding Cookies</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>You have the right to decide whether to accept or reject cookies. You can manage your cookie preferences by adjusting the settings in your web browser. Below are instructions on how to disable cookies in commonly used browsers:</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Google Chrome: </strong> Go to Settings &gt; Privacy and Security &gt; Cookies and other site data.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mozilla Firefox: </strong> Go to Options &gt; Privacy &amp; Security &gt; Cookies and Site Data.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Microsoft Edge: </strong> Go to Settings &gt; Privacy, Search, and Services &gt; Cookies and site permissions.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Safari: </strong> Go to Preferences &gt; Privacy &gt; Manage Website Data.</p>\n\n\
    <p style='margin-bottom: 10px !important;'>Please note that disabling cookies may affect the functionality of certain parts of our website.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Changes to This Cookies Policy</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>We may update this Cookies Policy from time to time to reflect changes in the technologies we use or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on this page.</p>\n\n\
    <p style='margin-bottom: 10px !important;'><strong>Contact Us</strong></p>\n\n\
    <p style='margin-bottom: 10px !important;'>If you have any questions about this Cookies Policy or how we use cookies, please contact us at:</p><br>\
    <p style='margin-bottom: 10px !important;'><strong>Producerbazaar Marketplace</strong></p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>S406, Trend India Workspaces Kochar Bliss (4th Floor)</p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>A8 &amp; A9 - Thiru Vi Ka Industrial Estate, Guindy,</p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>Chennai - 600032</p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>Tamil Nadu, India</p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>Phone: +91 72007 11366</p>\
    <p style='margin-bottom: 10px !important;margin-top: -10px !important;'>Email: info@fipchain.com</p>";

    return (
        <div>
            <Header />
            <div className="container">
                <Document title={title} data={documentData} />
            </div>
            <Footer />
        </div>
    )
}