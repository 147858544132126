import ToggleList from "../components/global/toggleList";

import aboutUsLanding from "../assets/aboutUslanding.png"
import bgPurple1 from "../assets/bgPurple1.png";
import bgPurple2 from "../assets/bgPurple2.png";
import aboutMidSection1 from "../assets/aboutUsStory.png";
import aboutMidSection2 from "../assets/aboutUsMidSection2.png";
import viewership from "../assets/viewers.png";

import teamMember from "../assets/teamMember.png";
import linkedIn from "../assets/linkedIn1.png";
import tirunavakkarasu from "../assets/tirunavukkarasu.png";
import vijay from "../assets/vijay.png";
import prabhu from "../assets/prabhu.png";
import vetriselvan from "../assets/vetri.png";
import helpline from "../assets/helpline.png";
import onboard from "../assets/onboard.png";
import moreReasonsImage from "../assets/moreReasons.png";
import checkCircle from "../assets/checkCircle.png";
import vision from "../assets/visionBG.png";
import whatsappImage from "../assets/whatsappImage.png";
import "../styles/aboutUs.css";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";
import { useNavigate } from "react-router-dom";
import FeaturedClients from "../components/homepage/FeaturedClients";
import SellBanner from "../components/homepage/SellBanner";

export const AboutUs = () => {
  const navigate = useNavigate();

  const backgroundStyle = {
    backgroundImage: `linear-gradient(90deg, #2F005D 40.5%, rgba(0, 0, 0, 0.5) 100%), url(${aboutUsLanding})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "30vh",
    width: "100vw",
  };

  const backGroundStyleSection1 = {
    backgroundImage: `url(${bgPurple1})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const backGroundStyleSection2 = {
    backgroundImage: `url(${bgPurple2})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100vw",
  };

  const VisionBackGroundStyle = {
    backgroundImage: `url(${vision})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    marginBottom: "-3rem"
  };

  const midSections = [
    {
      tagLine: "Our Story",
      content:
        "At ProducerBazaar, we are driven by a passion for storytelling and a commitment to empowering creators across the globe. Founded with the vision to bridge the gap between content creators and global buyers, we offer a digital marketplace for the seamless exchange of media rights. Our journey began with the desire to simplify the complexities of content syndication, helping filmmakers, producers, and creators monetize their work efficiently. Today, we leverage cutting-edge technology to unlock new opportunities, enabling content to reach audiences far and wide.",
      backgroundImage: backGroundStyleSection1,
      image: aboutMidSection1,
      imageLeft: true,
    },
    {
      tagLine: "",
      content:
        "ProducerBazaar market place is an intelligent platform designed for today’s industry need",
      backgroundImage: backGroundStyleSection2,
      image: aboutMidSection2,
      imageLeft: false,
    },
  ];

  const producerBazaarValues = [
    {
      image: viewership,
      content: "We enable producers and creators to showcase their content and unlock new opportunities in the entertainment industry.",
    },
    {
      image: viewership,
      content: "We foster a fair and open platform where content rights are traded with trust and integrity.",
    },
    {
      image: viewership,
      content: "We offer cutting-edge tools and insights to help producers maximize their content’s value across diverse channels.",
    },
    {
      image: viewership,
      content: "We believe in building lasting relationships with creators, distributors, and buyers to fuel mutual success in the ever-evolving media landscape.",
    },
  ];

  const teamMembers = [
    { name: "GK Tirunavukarasu", role: "Founder & CEO", image: tirunavakkarasu, linkedInProfile: "https://www.linkedin.com/in/gktirunavukarasu/" },
    { name: "Vijay D", role: "Co-founder & Chief Business Officer ", image: vijay, linkedInProfile: "https://www.linkedin.com/in/vijay-dingari/" },
    { name: "Vetriselvan", role: "Chief Operation Officer", image: vetriselvan, linkedInProfile: "https://www.linkedin.com/in/vetriselvanr/" },
    { name: "Prabhu G ", role: "Chief Finance Officer", image: prabhu, linkedInProfile: "https://www.linkedin.com/in/prabhugovindan/" }
  ];

  const Landing = () => {
    const tagLine = "Pioneering a new era of \n Digital rights Excellence";
    return (
      <div className="staticBuyCard">
        <div style={backgroundStyle}>
          <div className="tagLine">
            {tagLine.split("\n").map((line, index) => (
              <div key={index}>
                {line}
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const MidSection = () => {
    return (
      <div>
        {midSections.map((section) => {
          return (
            <div style={section.backgroundImage}>
              <div>
                {section.imageLeft && (
                  <div className="midSectionContainer">
                    <div className="width50">
                      <img className="midSectionImage" src={section.image} />
                    </div>
                    <div className="midSectionContentContainer width50">
                      <div className="midSectionTagLine">
                        {section.tagLine.split("\n").map((line, index) => (
                          <div key={index}>
                            {line}
                            <br />
                          </div>
                        ))}
                      </div>
                      <div className="midSectionContent">{section.content}</div>
                    </div>
                  </div>
                )}
                {!section.imageLeft && (
                  <div className="midSectionContainer">
                    <div className="midSectionContentContainer width50">
                      <div className="midSectionTagLine">
                        {section?.tagLine}
                      </div>
                      <div className="midSectionContent midSectionContentText">{section.content}</div>
                    </div>
                    <div className="width50">
                      <img className="midSectionImage" src={section.image} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

//   const MidSection = () => {
//     const [section1, section2] = midSections;
//     return (
//         <div>
//             <div style={section1.backgroundImage}>
//                 <div className="midSectionContainer">
//                     <div>
//                         <img className="midSectionImage" src={section1.image} />
//                     </div>
//                     <div className="midSectionContentContainer">
//                         <div className="midSectionTagLine">
//                         {section1.tagLine.split("\n").map((line, index) => (
//                             <div key={index}>
//                             {line}
//                             <br />
//                             </div>
//                         ))}
//                         </div>
//                         <div className="midSectionContent">{section1.content}</div>
//                     </div>
//                 </div>
//                 <div className="midSectionContainer">
//                     <div className="midSectionContentContainer">
//                       <div className="midSectionTagLine">
//                         {section2?.tagLine}
//                       </div>
//                       <div className="midSectionContent midSectionContentText">{section2?.content}</div>
//                     </div>
//                     <div>
//                       <img className="midSectionImage" src={section2?.image} />
//                     </div>
//                   </div>
//             </div>
//         </div>
//     )
//   }

  const ChooseProducerBazaar = () => {
    return (
      <div className="reasonsContainer">
        <div className="heading">Our Values</div>
        <div className="reasonsToChooseContainer">
          {producerBazaarValues.map((reason) => {
            return (
              <div className="reasonContainer">
                <img className="reasonImage" src={reason.image} />
                <div>{reason.content}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const Vision = () => {
    return (
      <div className="container" style={VisionBackGroundStyle}>
        <div>
          <div className="heading">
            <p style={{ marginBottom: '-30px' }}>Vision</p>
          </div>
          <div className="visionContainer">
            <p>Our vision is to revolutionize the interaction between creators and buyers, leveraging blockchain technology to create a safe, seamless, and efficient marketplace for buying and selling rights, empowering all parties involved.</p>
          </div>
        </div>
      </div>
    );
  };

  const TeamMember = (props: any) => {
    const { name, role, image, linkedInProfile } = props;
    return (
        <div className="team-member">
            <img src={image} alt={name} className="team-member-image" />
            <div className="team-member-info">
            <div className="team-member-name-container">
                <p className="team-member-name">{name}</p>
                <a href={linkedInProfile} target="_blank"><img src={linkedIn} className="linkedin-icon" /></a>
            </div>
            <p className="team-member-role">{role}</p>
            </div>
        </div>
    )
  };

  const OurTeam = () => (
    <div className="team-display">
        <p className="team-display-title">Our Team</p>
        <div className="team-member-container">
        {teamMembers.map((member, index) => (
            <TeamMember key={index} {...member} />
        ))}
        </div>
    </div>
  );

  return (
    <div>
      <div>
        <Header></Header>
        <Landing></Landing>
        <MidSection></MidSection>
        <ChooseProducerBazaar></ChooseProducerBazaar>
        <OurTeam></OurTeam>
        <Vision></Vision>
        <div className="featuredClientsContainer">
            <FeaturedClients></FeaturedClients>
        </div>
        <SellBanner></SellBanner>
        <Footer></Footer>
      </div>
    </div>
  );
}
