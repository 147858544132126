import { Document } from "../components/Document";
import Footer from "../components/global/Footer";
import Header from "../components/global/Header";

export const Support = () => {
    const title = 'Support';
    const documentData = [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean enim neque, sollicitudin sit amet eleifend vitae, tincidunt et augue. Phasellus condimentum elementum congue. Phasellus dolor felis, pellentesque id enim vitae, aliquam tristique ante. Mauris eu purus velit. Suspendisse in augue pellentesque, fringilla justo et, faucibus nulla. Ut finibus dui vitae orci posuere pharetra. Quisque facilisis tincidunt mattis. Nunc tempus gravida congue. Proin blandit est orci, ut mollis felis auctor eu. Suspendisse porttitor nunc quis orci varius, ut pulvinar velit elementum. In at semper tortor, a maximus mi. Nulla facilisi. Integer volutpat nec enim sit amet iaculis. Fusce tempus varius erat, aliquam ullamcorper metus iaculis eget. Phasellus vestibulum elementum velit, sit amet vestibulum neque maximus at.',
        'Proin sem nisl, dapibus eget maximus vel, pretium sed massa. Suspendisse potenti. Nullam vel aliquam erat. Donec egestas ipsum lorem, vel commodo nibh malesuada vitae. Pellentesque non ante luctus, lacinia est sed, aliquet metus. Curabitur scelerisque quam et enim tempus fermentum sed at orci. Maecenas nec urna id purus fermentum consectetur. Donec sed gravida massa.',
        'Maecenas non enim id elit placerat convallis. Etiam pharetra magna sodales justo interdum rhoncus. Vestibulum mollis porta tortor, id efficitur felis elementum a. Nunc ut condimentum nunc. Aliquam pellentesque lorem ut nisi fringilla laoreet. Phasellus lacus diam, pretium vitae auctor non, pulvinar non metus. Praesent congue lectus vitae augue imperdiet rhoncus. Aliquam euismod quam erat. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce pretium volutpat faucibus. Mauris suscipit ante quis libero ullamcorper iaculis. Etiam a elit nibh. Phasellus accumsan magna sed nisi interdum commodo. Proin eu nisi vestibulum, ullamcorper tellus quis, auctor metus. Cras vitae laoreet massa. Aliquam erat volutpat.',
        'Phasellus quis sem vel diam pharetra pulvinar. Phasellus iaculis nulla metus, eget vestibulum libero sodales eu. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Donec justo quam, venenatis non venenatis ac, vestibulum quis leo. Aliquam venenatis sem a aliquet elementum. Nunc blandit, dolor id auctor aliquet, dui elit feugiat nibh, a placerat quam enim in mauris. Sed in rutrum magna. Mauris sit amet dolor quis arcu ullamcorper auctor.',
        'Suspendisse sit amet tempus dui. In mattis a erat dictum facilisis. Phasellus condimentum ullamcorper orci, et pulvinar neque luctus sed. Mauris ut nulla interdum tortor pulvinar facilisis tempus at turpis. Ut augue erat, dictum at eros eu, porttitor aliquam risus. Phasellus interdum ultrices consectetur. Nullam aliquam commodo elit vel iaculis. Morbi dignissim sit amet neque vel aliquet. Donec facilisis erat ultrices volutpat scelerisque. Donec nec ligula ac risus ultricies ullamcorper.'
    ]
    return (
        <div>
            <Header />
            <div className="container">
                <Document title={title} data={documentData} />
            </div>
            <Footer />
        </div>
    )
}