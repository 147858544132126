import React, { useState } from "react";
import QRCode from "react-qr-code";
import { Modal } from "rsuite";
import { IoShareSocial } from "react-icons/io5";
import { emailRegex, nameRegex } from "../../utils/RegExpressions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { REACT_APP_DEV_URL } from "../../utils/api";
import toast from "react-hot-toast";
import { Loader } from "rsuite";

const QRSection = ({ data }: any) => {
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("Details");
  const handleOpen = () => setOpen(true);

  const [downloadInputValues, setDownloadInputValues] = useState({
    name: "",
    email: "",
    phoneno: "",
  });
  const [errors, setErrors] = useState({
    nameError: "",
    emailError: "",
    phoneError: "",
  });
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const token = sessionStorage.getItem("token") || "";

  const handleClose = () => {
    setOpen(false);
    setErrors({
      nameError: "",
      emailError: "",
      phoneError: "",
    });
    setDownloadInputValues({
      name: "",
      email: "",
      phoneno: "",
    });
    setTab("Details");
  };

  const handleChange = (e: any) => {
    const { id, value } = e.target;
    setDownloadInputValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const validateForm = () => {
    let errorMessages = {
      nameError: "",
      emailError: "",
      phoneError: "",
    };

    // Validate name
    if (downloadInputValues.name === "") {
      errorMessages.nameError = "Please enter your name.";
    } else if (
      downloadInputValues.name !== "" &&
      !nameRegex.test(downloadInputValues.name)
    ) {
      errorMessages.nameError = "invalid name";
    }

    // Validate email
    if (downloadInputValues.email === "") {
      errorMessages.emailError = "Please enter your email.";
    } else if (!emailRegex.test(downloadInputValues.email)) {
      errorMessages.emailError = "Please enter a valid email address.";
    }

    // Validate phone number
    if (downloadInputValues.phoneno === "") {
      errorMessages.phoneError = "Please enter your phone number.";
    } else if (
      !/^\d+$/.test(downloadInputValues.phoneno) || // Check if the input contains only numbers
      downloadInputValues.phoneno.length < 7 || // Minimum length of 7
      downloadInputValues.phoneno.length > 13 // Maximum length of 13
    ) {
      errorMessages.phoneError =
        "Please enter a valid phone number (7 to 13 digits).";
    }

    setErrors(errorMessages);

    // Return true if no errors
    return Object.values(errorMessages).every((msg) => msg === "");
  };

  const shareEmailApi = async (param: any) => {
    setLoading(true);
    const res = await axios.post(
      `${REACT_APP_DEV_URL}/selling-info/share`,
      param,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (res.status !== 200) {
      toast.error(res.data.message);
    } else {
      console.log("res", res.data.data.url);
      setUrl(res.data.data.url);
      // toast.success("Mail trigger initiated");
    }
    setLoading(false);
  };

  const shareEmailDocApi = async (param: any) => {
    const res = await axios.post(
      `${REACT_APP_DEV_URL}/selling-info/share-mail`,
      param,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res.status !== 200) {
      toast.error(res.data.message);
    } else {
      setUrl("");
      toast.success("Mail trigger initiated");
    }
  };

  const onSubmit = () => {
    if (validateForm()) {
      let temp: any = {
        name: downloadInputValues.name,
        email: downloadInputValues.email,
        phone: downloadInputValues.phoneno,
        share: location.pathname === "/market-place" ? "MULTIPLE" : "SINGLE",
      };
      if (location.pathname === "/market-place") {
        temp.data = data;
        shareEmailApi(temp);
        setTab("Scan");
        return;
      }
      temp.data = [data];
      shareEmailApi(temp);
      setTab("Scan");
      // handleClose();
    }
  };

  const onMailShare = () => {
    const temp = {
      email: downloadInputValues.email,
      url: url,
    };
    shareEmailDocApi(temp);
    handleClose();
  };
  return (
    // need to remove this container div

    <div className="downloadSection">
      <div className="QrFeatureSectionContainer">
        <div>
          <button className="downloadpdf" onClick={handleOpen}>
            <IoShareSocial />
            Share
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        className="downloadModal"
        size="sm"
      >
        <Modal.Header />
        <Modal.Body>
          <div style={{ padding: "40px" }}>
            {loading ? (
              <div className="loadingOverlay">
                <Loader
                  content="Hang tight, the show is about to begin..."
                  size="lg"
                />
              </div>
            ) : (
              <>
                <div
                  className="tabOptions w-full md:w-1/2 lg:w-1/4"
                  style={{ paddingTop: "0px" }}
                >
                  <p
                    className={`tabTextSell ${
                      tab === "Details" ? "activeTab" : ""
                    }`}
                    onClick={() => {
                      setTab("Details");
                    }}
                  >
                    Details
                  </p>
                  <p
                    className={`tabTextBuy ${
                      tab === "Scan" ? "activeTab" : ""
                    }`}
                  >
                    Scan
                  </p>
                </div>

                <>
                  {tab === "Details" ? (
                    <div>
                      <div className="downloadFields">
                        <p>Name:</p>
                        <input
                          type="name"
                          id="name"
                          onChange={handleChange}
                          value={downloadInputValues.name}
                        />
                      </div>
                      {errors.nameError && (
                        <p className="error">{errors.nameError}</p>
                      )}
                      <div className="downloadFields">
                        <p>Email Id:</p>
                        <input
                          type="email"
                          id="email"
                          onChange={handleChange}
                          value={downloadInputValues.email}
                        />
                      </div>
                      {errors.emailError && (
                        <p className="error">{errors.emailError}</p>
                      )}
                      <div className="downloadFields">
                        <p>Phone Number:</p>
                        <input
                          type="number"
                          id="phoneno"
                          onChange={handleChange}
                          value={downloadInputValues.phoneno}
                        />
                      </div>
                      {errors.phoneError && (
                        <p className="error">{errors.phoneError}</p>
                      )}

                      <button className="downloadSubmitBtn" onClick={onSubmit}>
                        {location.pathname === "/market-place"
                          ? "Submit"
                          : "Next"}
                      </button>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{
                          padding: "16px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <QRCode value={url} size={256} />
                      </div>
                      <button
                        className="downloadSubmitBtn"
                        onClick={onMailShare}
                      >
                        Share via Email
                      </button>
                    </>
                  )}
                </>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default QRSection;
